import { EventHistoryItem } from '@fc-core/notifications/models/event-history-item';
import { Component, input, output } from '@angular/core';
import { MatRipple } from '@angular/material/core';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'fc-event-item',
  imports: [MatRipple, RouterLink],
  template: ` <div
    class="event-item"
    matRipple
    [routerLink]="event().message.link.fullUrl"
    (click)="markAsRead.emit(event().id)"
  >
    <div class="image-container">
      <img [src]="event().message.image" alt="event-image" />
    </div>
    <div class="event-info">
      <div class="event-title h3">
        {{ event().message.title }}
      </div>
      <div class="event-subtitle">
        {{ event().message.body }}
      </div>
    </div>

    <div class="event-timing">
      <div class="event-time label color-label">
        {{ event().readAt }}
      </div>
      @if (!event().read) {
        <div class="event-read"></div>
      }
    </div>
  </div>`,
  styleUrls: ['./event-item.component.scss'],
})
export class EventItemComponent {
  event = input<EventHistoryItem>();
  markAsRead = output<string>();
}
